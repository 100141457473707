.talents {
  overflow: hidden;
  @media screen and (min-width: 768px) {
    display: flex;
    padding-left: 30px;
    position: relative;
    margin-bottom: 130px;
  }
  @media screen and (min-width: 1004px) {
    padding-left: 0;
    margin-bottom: 300px;
  }
  &__content {
    padding: 60px 25px 0;
    position: relative;
    z-index: 10;
    @media screen and (min-width: 768px) {
      width: 335px;
      flex-shrink: 0;
      padding: 0;
      padding-top: 150px;
      padding-bottom: 150px;
      background: linear-gradient(90deg, #000 40%, rgba(0, 0, 0, 0) 100%);
    }
    @media screen and (min-width: 1004px) {
      width: 450px;
      margin-top: 50px;
      margin-left: calc((100% - 1110px) / 2);
    }
  }
  &__caption {
    color: #fff;
    text-align: center;
    font-family: Manrope;
    font-size: 24px;
    font-weight: 700;
    line-height: 130%;
    letter-spacing: -1.2px;
    margin-bottom: 20px;
    @media screen and (min-width: 768px) {
      text-align: left;
    }
    @media screen and (min-width: 1004px) {
      font-size: 36px;
      letter-spacing: -1.8px;
    }
  }
  &__text {
    color: #c8c8c8;
    text-align: center;
    font-family: Manrope;
    font-size: 18px;
    line-height: 120%;
    @media screen and (min-width: 768px) {
      text-align: left;
      font-size: 16px;
    }
    @media screen and (min-width: 1004px) {
      font-size: 18px;
    }
  }
  &__btn {
    display: flex;
    width: 100%;
    padding: 20px 50px;
    justify-content: center;
    align-items: center;
    border-radius: 33px;
    background: rgba(0, 0, 0, 0.51);
    color: #c8c8c8;
    font-family: Manrope;
    border: 1px solid #717171;
    font-size: 18px;
    line-height: 24px;
    margin-top: 30px;
    text-decoration: unset;
    transition: all 0.3s ease-in-out;
    @media screen and (min-width: 768px) {
      font-size: 16px;
    }
    &:hover {
      background: #00f3bc;
      color: #000;
      border: 1px solid #00f3bc;
    }
  }
  &__video {
    display: flex;
    justify-content: center;
    position: relative;
    transform: translateY(-100px);
    opacity: 0.6;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 15%;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 1) 100%
      );
    }
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 15%;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 1) 100%
      );
    }
    @media screen and (min-width: 768px) {
      transform: unset;
      position: absolute;
      top: 0;
      right: 0;
      transform: translateX(25%);
    }
    @media screen and (min-width: 1004px) {
      &::after {
        height: 20%;
        bottom: 285px;
      }
    }
  }
  & video {
    width: 150%;
    @media screen and (min-width: 768px) {
      width: 120%;
    }
    @media screen and (min-width: 1004px) {
      width: 100%;
    }
  }
}
