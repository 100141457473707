.footer {
  padding: 0 20px;
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  @media screen and (min-width: 768px) {
    padding: 0 30px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding-bottom: 60px;
    column-gap: 90px;
  }
  @media screen and (min-width: 1004px) {
    padding-left: 0;
    padding-right: 0;
    max-width: 1110px;
    margin: 0 auto;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    column-gap: unset;
  }
  &__logo {
    display: inline-block;
    margin-bottom: 60px;
    grid-column-start: 1;
    grid-column-end: 3;
    text-decoration: none;
  }
  &__logo:visited {
    color: none;
  }
  &__title {
    color: #fff;
    font-family: Manrope;
    font-size: 24px;
    font-weight: 500;
    line-height: 120%;
    text-align: center;
    @media screen and (min-width: 768px) {
      font-size: 18px;
      text-align: left;
    }
  }
  &__cta {
    padding: 60px 0;
    grid-column-start: 1;
    grid-column-end: 3;
    @media screen and (min-width: 768px) {
      grid-row-start: 2;
      padding: 0;
    }
    @media screen and (min-width: 1004px) {
      display: flex;
      grid-column-end: 5;
      align-items: center;
      gap: 30px;
    }
  }
}
.footer-cta {
  margin-top: 20px;
  @media screen and (min-width: 1004px) {
    margin-top: 0;
  }
}

.footer-nav {
  display: flex;

  flex-wrap: wrap;
  &__list {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    gap: 20px;
    &_first {
      @media screen and (min-width: 1004px) {
        grid-column-start: 4;
        grid-column-end: 5;
      }
    }
    &_second {
      @media screen and (min-width: 1004px) {
        grid-column-start: 5;
        grid-column-end: 6;
      }
    }

    &_contacts {
      margin-top: 30px;
      grid-column-start: 1;
      grid-column-end: 3;
      @media screen and (min-width: 768px) {
        grid-column-start: 3;
        grid-column-end: 5;
      }
      @media screen and (min-width: 1004px) {
        grid-column-start: 6;
        grid-column-end: 7;
        margin-top: 0;
      }
    }
  }
  &__caption {
    color: #fff;
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  &__link {
    color: #c8c8c8;
    text-decoration: none;
    font-family: Manrope;
    font-size: 18px;
    line-height: 120%;

    &:hover {
      color: #fff;
    }
  }
}

.copyright {
  border-top: 1px solid #222;
  padding: 30px 70px;
  color: #6d6d6d;
  text-align: center;
  font-family: Manrope;
  font-size: 16px;
  line-height: 120%;
  & br {
    @media screen and (min-width: 1004px) {
      display: none;
    }
  }
}
