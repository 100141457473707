.team {
  padding: 0 20px;
  @media screen and (min-width: 768px) {
    padding: 0 30px;
  }
  @media screen and (min-width: 1004px) {
    padding: 0;
    max-width: 1110px;
    margin: 0 auto;
  }
  &__team {
    padding-top: 30px;
    @media screen and (min-width: 768px) {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
    }
    @media screen and (min-width: 1004px) {
      padding-top: 60px;
    }
  }
}

.team-card {
  &_last {
    display: none;
    & .team-card__name {
      margin-bottom: 10px;
    }
    & .team-card__position {
      font-size: 18px;
    }
    & .header-cta {
      width: 100%;
    }
    @media screen and (min-width: 768px) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-radius: 20px;
      border: 1px solid #222;
      background: #0d0d0d;
      padding: 30px;
    }
  }
  @media screen and (min-width: 768px) {
    width: calc(50% - 15px);
    position: relative;
  }
  @media screen and (min-width: 1004px) {
    width: calc(33.333% - 20px);
  }
  & + .team-card {
    margin-top: 30px;
    @media screen and (min-width: 768px) {
      margin-top: 0;
    }
  }
  &__info {
    @media screen and (min-width: 768px) {
      position: absolute;
      bottom: 30px;
      left: 30px;
    }
  }
  &__img {
    width: 100%;
    border-radius: 10px;
    border: 1px solid #222;
    margin-bottom: 20px;
    @media screen and (min-width: 768px) {
      margin-bottom: 0;
    }
  }
  &__name {
    display: block;
    color: #fff;
    font-family: Manrope;
    font-size: 24px;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -1.2px;
    margin-bottom: 5px;
  }
  &__position {
    display: block;
    color: #c8c8c8;
    font-family: Manrope;
    font-size: 18px;
    line-height: 120%;
  }
}
