@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700;800&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
@import "./normalize.scss";
body {
  font-family: "Manrope", sans-serif;
  background-color: #010101;
}

.g1, .g2 {
  display: none !important;
}

.caption-line {
  display: flex;
  align-items: center;
  color: #fff;
  font-family: "Manrope";
  font-size: 24px;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -1.2px;

  &::before {
    content: "";
    display: inline-block;
    height: 30px;
    width: 2px;
    background-color: #00f3bc;
    margin-right: 20px;
    border-radius: 2px;
  }
  @media screen and (min-width: 768px) {
    font-size: 18px;
    letter-spacing: -0.9px;
  }
  @media screen and (min-width: 1004px) {
    font-size: 24px;

    letter-spacing: -1.2px;
  }
}
.overflowed {
  overflow: hidden;
}


.offices {
  color: white;
  font-family: Manrope;
  text-align: left;
  padding-top: 10px;
  border: none;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  &>span {
    margin-top: 10px;
    text-decoration: wavy;
  }
}


@import "./header";
@import "./map";
@import "./solution";
@import "./plans";
@import "./limits";
@import "./slider";
@import "./team";
@import "./values";
@import "./drive";
@import "./talents";
@import "./footer";
