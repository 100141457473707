.plans {
  padding: 0 20px;
  &__cta {
    display: none;
    border-radius: 73px;
    border: 1px solid #6d6d6d;
    background: rgba(0, 0, 0, 0.5);
    color: #6d6d6d;
    font-family: Manrope;
    font-size: 18px;
    line-height: 24px; /* 133.333% */
    letter-spacing: -0.9px;
    padding: 18px 32px;
    margin-top: 30px;
    margin-left: auto;
    @media screen and (min-width: 768px) {
      display: flex;
    }
  }
}
.plans-xs {
  margin-top: 30px;
  @media screen and (min-width: 768px) {
    display: none;
  }
}
.plans-md {
  display: none;
  border-radius: 20px;
  border: 1px solid #6d6d6d;
  background: #0d0d0d;
  margin-top: 30px;
  padding: 30px;
  @media screen and (min-width: 768px) {
    display: flex;
  }
  &__col {
    &_centered {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 48px;
    }
  }
  &__caption {
    color: #fff;
    font-family: Manrope;
    font-size: 24px;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -1.2px;
    padding: 19px 0;
    margin-bottom: 20px;
  }
  &__item {
    color: #c8c8c8;
    font-family: Manrope;
    font-size: 18px;
    line-height: 120%;
    padding: 19px 0;
  }
  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    &_l {
      height: 81px;
    }
  }
}
.plan-xs {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  width: 100%;
  border-radius: 20px;
  border: 1px solid #222;
  background-color: unset;
  color: #c8c8c8;
  font-family: Manrope;
  font-size: 18px;
  font-weight: 500;
  line-height: 17.872px;
  letter-spacing: -0.9px;
  margin-bottom: 10px;
}

.plan-xs-view {
  visibility: hidden;
  opacity: 0;
  height: 0;

  border-radius: 10px;
  border: 1px solid #222;
  background: #0d0d0d;
  &.active {
    padding: 30px 20px;
    margin: 10px 0;
    opacity: 1;
    visibility: visible;
    height: auto;
  }
  &__item {
    padding-left: 15px;
    height: 60px;
    display: flex;
    align-items: center;
    gap: 19px;
    color: #c8c8c8;
    font-family: Manrope;
    font-size: 18px;
    line-height: 120%;
    & svg {
      flex-shrink: 0;
    }
  }
  &__btn {
    margin-top: 30px;
    padding: 18px 32px;
    width: 100%;
    color: #00f3bc;
    font-family: Manrope;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.9px;
    border-radius: 73px;
    border: 1px solid #00f3bc;
    background: rgba(0, 0, 0, 0.5);
  }
}
