.map {
  padding: 120px 20px 0;
  @media screen and (min-width: 768px) {
    padding: 230px 30px 130px;
  }
  @media screen and (min-width: 1004px) {
    max-width: 1110px;
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 200px;
  }
  &__caption {
    color: #fff;
    font-family: "Manrope";
    font-size: 24px;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -1.2px;
    margin-bottom: 20px;
    @media screen and (min-width: 768px) {
      font-size: 24px;
      max-width: 560px;
    }
    @media screen and (min-width: 1004px) {
      font-size: 36px;
      letter-spacing: -1.8px;
      max-width: 850px;
    }

    &-sub-text {
      color: #c8c8c8;
      font-family: "Manrope";
      font-size: 16px;
      line-height: 127.5%;
      letter-spacing: -0.8px;
      margin-bottom: 50px;
      max-width: 560px;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 42px 22px 0;
    border-radius: 20px;
    border: 1px solid #222;
    background: #000;
    box-shadow: 20px 20px 35px 0px rgba(34, 34, 34, 0.2);

    overflow: hidden;
    @media screen and (min-width: 768px) {
      flex-direction: row;
      padding-left: 42px;
      padding-bottom: 42px;
    }
    @media screen and (min-width: 1004px) {
      height: 485px;
      padding-left: 60px;
    }
  }
  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (min-width: 768px) {
      align-items: flex-start;
    }
  }
  &__title {
    color: #00f3bc;
    text-align: center;
    font-family: "Manrope";
    font-size: 36px;
    font-weight: 700;
    @media screen and (min-width: 768px) {
      font-size: 28px;
      text-align: left;
    }
  }
  &__text {
    color: #fff;
    font-family: "Manrope";
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 120%;
    margin: 20px 0 10px;
    @media screen and (min-width: 768px) {
      font-weight: 400;
      text-align: left;
      max-width: 440px;
    }
    @media screen and (min-width: 1004px) {
      font-size: 24px;
      letter-spacing: -1.2px;
      font-weight: 500;
      max-width: 600px;
    }
  }
  &__sub-text {
    color: #c8c8c8;
    font-family: "Manrope";
    font-size: 16px;
    line-height: 127.5%;
    letter-spacing: -0.8px;
    @media screen and (min-width: 1004px) {
      font-size: 18px;
    }
  }
  &__video {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 310px;
    overflow: hidden;
    @media screen and (min-width: 1004px) {
      height: 380px;
    }
    @media screen and (max-width: 440px) {
      height: 210px;
    }
  }
  & video {
    position: absolute;
    height: 130%;
    bottom: -100px;
    @media screen and (min-width: 768px) {
      position: relative;
      bottom: 0;
      transform: scale(1.21);
    }
    @media screen and (min-width: 1004px) {
      height: 380px;
      left: 40px;
    }
  }
}
