.limits {
  padding: 100px 20px;
  @media screen and (min-width: 768px) {
    padding: 130px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  svg {
    display: none;
    @media screen and (min-width: 768px) {
      display: block;
      transform: translateY(30px);
    }
    @media screen and (min-width: 1004px) {
      width: 100%;
      transform: translateY(90px);
    }
  }
  &__caption {
    text-align: center;
    color: #fff;
    font-family: Manrope;
    font-size: 36px;
    font-weight: 700;
    line-height: 130%;
    letter-spacing: -1.8px;
    margin-bottom: 20px;
    @media screen and (min-width: 768px) {
      font-size: 74px;
      line-height: 120%;
    }
    @media screen and (min-width: 1004px) {
      font-size: 120px;
      line-height: 120%;
      letter-spacing: -6px;
    }
  }
  &__text {
    color: #c8c8c8;
    text-align: center;
    font-family: Manrope;
    font-size: 18px;
    font-weight: 400;
    line-height: 120%;
    @media screen and (min-width: 768px) {
      font-size: 16px;
      max-width: 440px;
    }
    @media screen and (min-width: 1004px) {
      font-size: 24px;
      max-width: 650px;
    }
  }
}
