.drive {
  margin: auto;
}
.drive-items-wrap {
  position: relative;
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: 40px;
  padding: 24px 0;
  border: 1px solid #222;
  background: #0d0d0d;
}
.drive-items-wrap:before,
.drive-items-wrap:after {
  content: "";
  height: 100%;
  top: 0;
  width: 10%;
  position: absolute;
  z-index: 1;
  pointer-events: none;
}

.drive-items {
  flex-shrink: 0;
  display: flex;
  gap: 40px;
  counter-reset: item;
  justify-content: space-around;
  min-width: 100%;
}
.drive-item {
  transition: all 0.1s ease-in-out;
  color: #fff;
  font-family: Manrope;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
}

.marquee {
  animation: scroll 20s linear infinite;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - 20px));
  }
}
