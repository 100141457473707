.solution {
  padding: 100px 20px;
  @media screen and (min-width: 768px) {
    padding: 0 30px 130px;
  }
  @media screen and (min-width: 1004px) {
    max-width: 1110px;
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
  }
  &__caption {
    margin-bottom: 30px;
    @media screen and (min-width: 1004px) {
      margin-bottom: 60px;
    }
  }
  &__content {
    @media screen and (min-width: 768px) {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
    }
    @media screen and (min-width: 1004px) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-areas:
        "a a b"
        "c c b"
        "d e e"
        "d g g"
    }
  }
  &__content2 {
    @media screen and (min-width: 768px) {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
    }
    @media screen and (min-width: 1004px) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 2fr;
      grid-template-areas:
        "a b b"
    }
  }
  &__content3 {
    @media screen and (min-width: 768px) {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
    }
    @media screen and (min-width: 1004px) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-areas:
        "a a b"
        "c c b"
        "d e e"
        "d e e"
    }
  }
}

.marsolution {
  padding: 100px 20px;
  @media screen and (min-width: 768px) {
    padding: 0 30px 130px;
  }
  @media screen and (min-width: 1004px) {
    max-width: 1110px;
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
  }
  &__caption {
    margin-bottom: 30px;
    @media screen and (min-width: 1004px) {
      margin-bottom: 60px;
    }
  }
  &__content {
    @media screen and (min-width: 768px) {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
    }
    @media screen and (min-width: 1004px) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-areas:
        "a b b"
    }
  }
  &__content2 {
    @media screen and (min-width: 768px) {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
    }
    @media screen and (min-width: 1004px) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 2fr;
      grid-template-areas:
        "a b b"
    }
  }
  &__content3 {
    @media screen and (min-width: 768px) {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
    }
    @media screen and (min-width: 1004px) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-areas:
        "a a b"
        "c c b"
        "d e e"
        "d e e"
    }
  }
}

.tech-card {
  @media screen and (min-width: 768px) {
    width: calc(50% - 15px);
  }
  @media screen and (min-width: 1004px) {
    width: 100%;
    border-radius: 20px;
    border: 2px solid #222;
    background: #0d0d0d;
  }
  @media screen and (max-width: 1004px) {
    transform: none !important;
    .js-tilt-glare {
      display: none !important;
    }
  }
  & + .tech-card {
    margin-top: 30px;
    @media screen and (min-width: 768px) {
      margin-top: unset;
    }
  }
  &__next-line {
    display: none;
    @media screen and (min-width: 1004px) {
      display: block;
    }
  }
  &__tag {
    display: none;
    color: #c8c8c8;
    font-family: Manrope;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.6px;
    padding: 6px 26px;
    border-radius: 65px;
    border: 1px solid #c8c8c8;
    opacity: 0.5;
    @media screen and (min-width: 1004px) {
      display: inline-block;
    }
  }
  &__img {
    width: 100%;
  }
  &__title {
    color: #fff;
    font-family: "Manrope";
    font-size: 24px;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: -1.2px;
    margin: 20px 0 10px;
  }
  &__text {
    color: #b2b2b2;
    font-family: "Manrope";
    font-size: 18px;
    font-weight: 400;
    line-height: 120%;
  }
  &_11,
  &_1 {
    grid-area: a;
  }
  &_21,
  &_2 {
    grid-area: b;
  }
  &_3 {
    grid-area: c;
  }
  &_4 {
    grid-area: e;
  }
  &_6 {
    grid-area: d;
  }
  &_8 {
    grid-area: g;
  }
  &_11 {
    @media screen and (min-width: 1004px) {
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-around;
      padding-left: 40px;
      padding-right: 40px;
      gap: 17px;
      & picture {
        border-radius: 10px;
        overflow: hidden;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        & img {
          width: 203.535px;
          height: 195px;
        }
      }
    }
  }
  &_1,
  &_3 {
    @media screen and (min-width: 1004px) {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      height: 270px;
      padding-left: 40px;
      gap: 17px;
      & picture {
        border-radius: 10px;
        overflow: hidden;
        flex-shrink: 0;
      }
    }
  }
  &_2 {
    @media screen and (min-width: 1004px) {
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
      & .tech-card__content {
        padding: 40px 40px 0;
      }
    }
  }
  &_21 {
    @media screen and (min-width: 1004px) {
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
      padding: 40px 40px 0;
    }
  }
  &_4 {
    @media screen and (min-width: 1004px) {
      display: flex;
      align-items: center;
      flex-direction: column-reverse;
      justify-content: space-between;
      & picture {
        width: 240px;
      }
      & .tech-card__content {
        padding: 40px 40px 0;
      }
    }
  }
  &_5 {
    @media screen and (min-width: 1004px) {
      display: flex;
      align-items: center;
      flex-direction: column-reverse;
      justify-content: space-between;
      & .tech-card__content {
        padding: 40px 40px 0;
      }
      & picture {
        width: 500px;
        margin-bottom: 60px;
      }
    }
  }
  &_6 {
    @media screen and (min-width: 1004px) {
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
      align-items: center;
      & .tech-card__content {
        padding: 40px 40px 0;
      }
      & picture {
        width: 380px;
        transform: translateY(4px);
      }
    }
  }
  &_7 {
    @media screen and (min-width: 1004px) {
      display: flex;
      flex-direction: row-reverse;

      & picture {
        flex-shrink: 0;
        align-self: center;
        margin-left: 50px;
        margin-right: 65px;
      }
      & .tech-card__content {
        padding-top: 40px;
        padding-left: 40px;
      }
    }
  }
  &_8 {
    @media screen and (min-width: 1004px) {
      display: flex;
      flex-direction: row-reverse;
      & picture {
        flex-shrink: 0;
        align-self: center;
        margin-right: 40px;
      }
      & .tech-card__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        padding: 40px;
      }
    }
  }
}

.marsolution .tech-card_1 {
  height: initial;
  padding-left: 0;
  @media screen and (min-width: 1004px) {
    display: flex;
    flex-direction: column-reverse;
    justify-content: start;
    align-items: center;
    & .tech-card__content {
      padding: 40px 40px 0;
    }
    & picture {
      width: 200px;
      padding-top: 20%;
    }
  }
}
.marsolution .tech-card_2 {
  height: initial;
  padding-left: 0;
  @media screen and (min-width: 1004px) {
    & picture {
      padding: 60px;
    }
  }
}