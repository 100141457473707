@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";
@import "node_modules/@glidejs/glide/src/assets/sass/glide.theme";

.slider {
  padding: 100px 20px;
  @media screen and (min-width: 768px) {
    padding: 0 30px 130px;
  }
  @media screen and (min-width: 1004px) {
    padding: 200px 0;
    max-width: 1110px;
    margin: 0 auto;
  }
  .glide__slide {
    &:hover {
      z-index: 100;
    }
    &::before {
      content: "";
      display: block;
      height: 25%;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      z-index: 100;
      background: linear-gradient(
                      180deg,
                      rgba(13, 13, 13, 1) 0%,
                      rgba(13, 13, 13, 0) 100%
      );
      @media screen and (min-width: 768px) {
        width: 70%;
        height: 100%;
        left: -20%;
        top: 0;
        z-index: 99;
        background: linear-gradient(
                        90deg,
                        rgba(13, 13, 13, 1) 60%,
                        rgba(13, 13, 13, 0) 100%
        );
      }
    }
  }

  &__info {
    position: relative;
    z-index: 105;
  }
  &__caption {
    color: #fff;
    font-family: Manrope;
    font-size: 24px;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -1.2px;
    @media screen and (min-width: 1004px) {
      font-size: 36px;
      letter-spacing: -1.8px;
    }
  }
  &__title {
    color: #fff;
    text-align: center;
    padding: 0 30px;
    font-family: Manrope;
    font-size: 24px;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -1.2px;
    margin-bottom: 20px;
    @media screen and (min-width: 768px) {
      max-width: 300px;
      text-align: left;
    }
    @media screen and (min-width: 1004px) {
      max-width: 470px;
      font-size: 36px;
      letter-spacing: -1.8px;
      padding: 0;
      padding-left: 65px;
    }
  }
  &__text {
    padding: 0 30px;
    color: #c8c8c8;
    text-align: center;
    font-family: Manrope;
    font-size: 18px;
    line-height: 120%;
    letter-spacing: -0.9px;
    @media screen and (min-width: 768px) {
      max-width: 300px;
      text-align: left;
    }
    @media screen and (min-width: 1004px) {
      font-size: 18px;
      max-width: 470px;
      padding: 0;
      padding-left: 65px;
    }
  }
  &__sub {
    display: block;
    color: #6d6d6d;
    font-family: Manrope;
    font-size: 18px;
    line-height: 120%;
    margin-top: 20px;
    padding: 0 30px;
    @media screen and (min-width: 1004px) {
      padding: 0;
      padding-left: 65px;
    }
  }
  &__video {
    margin-top: 20px;
    position: relative;
    @media screen and (min-width: 768px) {
      position: absolute;
      width: 90%;
      height: 100%;
      right: 0;
      top: 0;
      margin-top: 0;
    }
  }
  & video {
    position: absolute;
    right: 0;
    width: 100%;
    transform: translateY(4px);
    opacity: .99;
    @media screen and (min-width: 768px) {
      transform: unset;
      height: 100%;
      width: auto;
    }
  }
}
.glide {
  margin: 30px 0;
}

.glide__slide {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  min-height: 100%;
  padding-top: 30px;
  border-radius: 20px;
  background: #0d0d0d;
  box-shadow: 20px 20px 35px 0px rgba(34, 34, 34, 0.2);
  border: 1px solid #6d6d6d;
  overflow: hidden;
  @media screen and (min-width: 768px) {
    flex-direction: row;
    position: relative;
    padding-top: 60px;
    padding-bottom: 80px;
  }
  @media screen and (min-width: 1004px) {
    padding-top: 140px;
    padding-bottom: 140px;
  }
}

.glide__bullets {
  bottom: -30px;
  @media screen and (min-width: 768px) {
    bottom: 50px;
    left: 30px;
    transform: unset;
  }
  @media screen and (min-width: 1004px) {
    left: 65px;
    bottom: 60px;
  }
}

.glide__bullet {
  width: 58px;
  height: 2px;
  border-radius: 2px;
  background-color: #6d6d6d;
  &--active {
    background-color: #00f3bc;
  }
}
