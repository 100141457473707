.values {
  padding: 100px 20px;
  &__inner {
    padding-top: 30px;
    @media screen and (min-width: 768px) {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
    }
    @media screen and (min-width: 1004px) {
      padding-top: 60px;
      gap: 60px;
    }
  }
  @media screen and (min-width: 768px) {
    padding: 130px 30px;
  }
  @media screen and (min-width: 1004px) {
    padding: 200px 0;
    max-width: 1110px;
    margin: 0 auto;
  }
}

.values-card {
  @media screen and (min-width: 768px) {
    width: calc(50% - 15px);
  }
  @media screen and (min-width: 1004px) {
    width: calc(50% - 30px);
  }
  & + .values-card {
    margin-top: 30px;
    @media screen and (min-width: 768px) {
      margin-top: unset;
    }
  }
  &__title {
    color: #fff;
    font-family: Manrope;
    font-size: 24px;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -1.2px;
    margin-bottom: 10px;
    @media screen and (min-width: 768px) {
      font-size: 18px;
    }
    @media screen and (min-width: 1004px) {
      font-size: 24px;
      margin-bottom: 20px;
    }
  }
  &__text {
    color: #c8c8c8;
    font-family: Manrope;
    font-size: 18px;
    line-height: 120%;
    @media screen and (min-width: 768px) {
      font-size: 16px;
    }
    @media screen and (min-width: 1004px) {
      font-size: 18px;
    }
  }
}
