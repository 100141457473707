.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 63px;
  background-color: #000;
  padding: 0 20px;
  position: sticky;
  left: 0;
  top: 0;
  z-index: 1000;
  @media screen and (min-width: 768px) {
    padding: 0 30px;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(15px);
  }
  @media screen and (min-width: 1200px) {
    background: rgba(0, 0, 0, 0.4);
    height: 85px;
    padding: 0 calc((100% - 1110px) / 2);
  }
  &__logo img {
    @media screen and (min-width: 1004px) {
      height: 50px;
    }
  }
}
.nav {
  display: none;
  align-items: center;
  gap: 30px;
  &__link {
    color: #fff;
    font-family: Manrope;
    font-size: 18px;
    font-weight: 400;
    line-height: 120%;
    text-decoration: none;
  }
  & .header-cta {
    margin-top: unset;
  }
  &__options {
    display: flex;
    align-items: center;
    margin-left: 90px;
    gap: 30px;
  }
  &__langs {
    display: flex;
    gap: 15px;
  }
  &__lang {
    color: #fff;
    font-family: Manrope;
    font-size: 18px;
    line-height: 120%;
    text-decoration: none;
  }
  @media screen and (min-width: 1004px) {
    display: inline-flex;
  }
}
.nav-burger {
  background: unset;
  border: 0;
  svg {
    fill: #fff;
    transition: all 0.3s linear;
  }
  &.active svg {
    fill: #00f3bc;
  }
  @media screen and (min-width: 1004px) {
    display: none;
  }
}

.video {
  padding: 0 20px;
  height: calc(100vh - 60px);
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 300px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 50.73%);
    position: absolute;
    bottom: -1px;
    left: 0;
    z-index: 10;
  }
  &__content {
    padding: 0 20px;
    z-index: 20;
    font-size: 50px;
    position: absolute;
    bottom: 0;
    left: 0;
    @media screen and (min-width: 768px) {
      padding: 0 30px;
    }
    @media screen and (min-width: 1004px) {
      padding: 0;
      max-width: 1110px;
      margin: 0 auto;
      left: 0;
      right: 0;
      bottom: 60px;
    }
  }
  & video {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }
  &__caption {
    color: #fff;
    font-size: 26px;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -1.3px;
    @media screen and (min-width: 768px) {
      font-size: 40px;
      max-width: 585px;
      margin-bottom: 20px;
    }
    @media screen and (min-width: 1004px) {
      min-width: 820px;
      font-size: 56px;
      letter-spacing: -2.8px;
    }
  }
  &__text {
    color: #c8c8c8;
    font-size: 18px;
    line-height: 120%;
    @media screen and (min-width: 768px) {
      font-size: 24px;
      max-width: 560px;
    }
    @media screen and (min-width: 1004px) {
      min-width: 865px;
      font-size: 24px;
    }
  }
}
.header-cta {
  display: inline-flex;
  width: 100%;
  overflow: hidden;
  border-radius: 35px;
  white-space: nowrap;
  color: #c8c8c8;
  font-family: "Manrope";
  font-size: 18px;
  line-height: 24px;
  background-color: unset;
  margin-top: 30px;
  text-decoration: none;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-top: 2px;
  padding-bottom: 2px;
  &::before {
    content: "";
    position: absolute;
    width: 200%;
    height: 500px;
    background-image: conic-gradient(
      transparent,
      transparent,
      transparent,
      #00f3bc
    );
    animation: cta-anima 4s linear infinite;
  }
  &:hover {
    color: #fff;
    background-color: #1a1a1a;
  }
  span {
    width: calc(100% - 2px);

    position: relative;
    z-index: 1;

    border-radius: 35px;
    background-color: #000;
    padding: 20px 0;
    @media screen and (min-width: 768px) {
      padding: 20px 50px;
    }
    @media screen and (min-width: 1004px) {
      padding: 14px 50px;
    }
  }
  @media screen and (min-width: 768px) {
    width: unset;
    padding-top: 2px;
    padding-bottom: 2px;
  }
}

.mobile-nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 20px;
  position: absolute;
  width: 100%;
  height: calc(100vh - 63px);
  background-color: #0d0d0d;
  top: 100%;
  left: 0;
  z-index: 1000;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s linear;
  &.active {
    visibility: visible;
    opacity: 1;
  }
  &__list {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin: 30px 0;
  }
  &__item {
    padding: 5px 15px;
  }
  &__link {
    color: #fff;
    font-family: Manrope;
    font-size: 18px;
    line-height: 120%;
    text-decoration: none;
  }
  &__langs {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  &__lang {
    color: #fff;
    font-family: Manrope;
    font-size: 18px;
    line-height: 120%;
    text-decoration: none;
    &.active {
      font-weight: 600;
    }
  }
  &__contacts {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 30px;
  }
  &__contacts-title {
    color: #fff;
    font-family: Manrope;
    font-size: 18px;
    font-weight: 600;
  }
  &__contacts-email {
    color: #c8c8c8;
    font-family: Manrope;
    font-size: 18px;
    line-height: 120%;
    text-decoration: none;
  }
  & .header-cta {
    @media screen and (min-width: 768px) {
      display: flex;
      margin: 0 auto;
    }
  }
}

@keyframes cta-anima {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
